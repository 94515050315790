import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

// Router
import {
    createBrowserRouter,
    RouterProvider,
    useNavigate,
    useLocation
} from "react-router-dom";

// Import static resources
import Logo from '../resources/images/internal/Diekmeier-Thuja-Logo.PNG';

const pages = ['Mission', 'Kunden', 'Galerie', 'Kontakt', 'Impressum'];

const ResponsiveAppBar = (props) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        setIsSmallScreen(windowSize.current[0] < 1200) // width >900
    }, [windowSize]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleScroll = (page) => {
        switch (page) {
            case "Mission":
                console.log("Scrolling to Mission")
                if (location.pathname !== "/") {
                    navigate("/")
                    setTimeout(() => {
                        props.missionRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }, 500)
                } else {
                    props.missionRef.current?.scrollIntoView({ behavior: 'smooth' })
                }
                break;
            case "Kunden":
                console.log("Scrolling to Kunden")
                if (location.pathname !== "/") {
                    navigate("/")
                    setTimeout(() => {
                        props.clientsRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }, 500)
                } else {
                    props.clientsRef.current?.scrollIntoView({ behavior: 'smooth' })
                }
                break;
            case "Galerie":
                console.log("Scrolling to Galerie")
                if (location.pathname !== "/") {
                    navigate("/")
                    setTimeout(() => {
                        props.GalerieRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }, 500)
                } else {
                    props.GalerieRef.current?.scrollIntoView({ behavior: 'smooth' })
                }
                break;
            case "Kontakt":
                console.log("Scrolling to Kontakt")
                if (location.pathname !== "/") {
                    navigate("/")
                    setTimeout(() => {
                        props.contactRef.current?.scrollIntoView({ behavior: 'smooth' })
                    }, 500)
                } else {
                    props.contactRef.current?.scrollIntoView({ behavior: 'smooth' })
                }
                break;
            case "Impressum":
                navigate("/Impressum")
                break;
            default:
                break;
        }
    }


    return (
        <AppBar position="static" sx={{ bgcolor: "white", borderBottom: "3px solid grey" }} >
            <Container maxWidth="xl" sx={{}}>
                <Toolbar sx={{ display: 'flex'}}>
                <img src={Logo} style={{ maxHeight: 80, marginRight: isSmallScreen?"0px":"1vw", width:isSmallScreen?"auto":"auto"}} alt="Logo"></img>
                    <Box sx={{ flexGrow: 1, display: { xs: 'relative', md: 'flex'} }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleScroll(page)}
                                sx={{ my: 2, color: 'black', display: 'relative', fontSize: "12pt", margin:"0px 0px 0px 0px" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
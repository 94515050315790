import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';

export default function MediaCard(props) {
  React.useEffect(() => {
    console.log(props.client);
  }, []);

  return (
    <Box p={4}>
      {'name' in props.client && (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }} gap={6}>
          <Box bg="white" boxShadow="md" borderRadius="lg" overflow="hidden" style={{"border":"2px solid lightgrey", padding:"5px"}}>
            <Box p={5}>
              <Text fontSize="xl" fontWeight="normal" mb={4}>
                {props.client.review}
              </Text>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Text fontSize="md">{props.client.description}</Text>
                <Text fontSize="md" fontWeight="bold">
                  {props.client.name} aus {props.client.ort}
                </Text>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );
}


export const data =
{
    "clients": [
        {
            "name":"Pascal G.",
            "ort":"Lankwitz",
            "review":`Ich habe 120 Thuja Smaragd für meine Hecke hier in Lankwitz gekauft und bin absolut begeistert! 
            Die Pflanzen kamen in top Zustand an, waren super verpackt und haben sich nach dem Einpflanzen schnell akklimatisiert. 
            Meine Hecke sieht jetzt schon dicht und lebendig aus - ein echter Hingucker! 
            Ein großes Dankeschön an das Team für die ausgezeichnete Qualität und den reibungslosen Ablauf. 
            Absolut empfehlenswert für jeden, der seinem Garten ein grünes Upgrade verpassen möchte!
            `
        },
        {
            "name":"Jörgen E.",
            "ort":"Steglitz",
            "review":`Ich habe für meinen Garten in Steglitz 200 Thuja Smaragd bestellt und bin von der Qualität beeindruckt. Jede Pflanze war gesund und kräftig, genau das, was ich für meine Heckenprojekt gesucht habe. Die Lieferung war blitzschnell und der Kundenservice erstklassig. Meine neue Hecke ist das Gesprächsthema in der Nachbarschaft. Ein riesiges Lob an das Team!
            `
        },{
            "name":"Manfred H.",
            "ort":"Potsdam",
            "review":`Für unser neues Heim in Potsdam haben wir uns entschieden, 50 Thuja Smaragd für die Vorgartenhecke zu bestellen. Sie kamen in perfektem Zustand an und waren sogar größer als erwartet! Die Anpflanzung war kinderleicht und schon nach wenigen Wochen konnten wir ein deutliches Wachstum sehen. Dieser Kauf hat unsere Erwartungen übertroffen. Wir können es kaum erwarten, zu sehen, wie prächtig die Hecke im nächsten Jahr aussieht!
            `
        },{
            "name":"Kevin G.",
            "ort":"Heiligensee",
            "review":`Letzten Monat habe ich 80 Thuja Smaragd für unser Grundstück in Heiligensee gekauft. Von der Bestellung bis zur Anlieferung lief alles reibungslos. Die Thujas waren sorgfältig verpackt, sodass keine Beschädigungen auftraten. Nach dem Einpflanzen haben sie sich gut entwickelt und beginnen bereits, eine dichte Hecke zu formen. Ein großes Dankeschön für die hervorragende Qualität und den tollen Service. Ich empfehle euch wärmstens weiter!
            `
        }
    ]
}
import * as React from "react";
import { useRef, forwardRef, useEffect, useState } from "react"
import { Box, Typography, Grid, Divider, FormControl, Checkbox, Switch, TextField, Button } from "@mui/material";

// Custom components
import Card from "./card"
import ContactForm from './contactForm'

// Images and other files
// import VillaImg from '../../resources/images/external/luxusvilla-mit-pool.jpg'
import BackgroundImage from '../../resources/images/internal/P_20180930_180157.jpg'
import ExampleImage1 from '../../resources/images/internal/PHOTO-2020-08-31-17-47-55.jpg'
import ExampleImage2 from '../../resources/images/internal/PHOTO-2020-05-28-15-09-40.jpg'
import ExampleImage3 from '../../resources/images/internal/PHOTO-2020-05-28-16-02-23.jpg'
import ExampleImage4 from '../../resources/images/internal/PHOTO-2020-08-31-17-48-00.jpg'
import ExampleImage5 from '../../resources/images/internal/PHOTO-2020-08-31-17-47-56.jpg'
import ExampleImage6 from '../../resources/images/internal/PHOTO-2020-08-31-17-47-58.jpg'
import ExampleImage7 from '../../resources/images/internal/P_20181014_133416.jpg'
import ExampleImage8 from '../../resources/images/internal/P_20200516_193121.jpg'


const Home = (props) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [scrollHeight, setScrollHeight] = useState("1080px")
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [shownCardsCount, setShownCardsCount] = useState(3);
    const [cardLG, setCardLG] = useState([]);
    var body = document.body,
        html = document.documentElement;

    useEffect(() => {
        let tempArr = []
        props.data.clients.forEach(elem => {
            tempArr.push(3)
        })
        setCardLG(tempArr)
    }, [props.data.clients]);

    useEffect(() => {
        setIsSmallScreen(windowSize.current[0] < 1200) // width >900
    }, [windowSize]);

    useEffect(() => {
        setScrollHeight(Math.max(body.offsetHeight, html.offsetHeight))
    }, [body.offsetHeight, html.offsetHeight, cardLG])

    return (
        <Grid container style={{ marginTop: "0em", display: "flex" }} >
            <Grid item xs={12} md={12} alignItems={"center"} alignContent={"center"}>
                <Grid container
                    style={{ marginTop: "0em" }}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item style={{ height: "5vh" }}></Grid>
                    <Grid item style={{ backgroundColor: "rgba(255, 255, 255, 0.95)", padding: isSmallScreen?"1.5em":"3em", borderRadius: "3px", width: "100%"}}>
                        <Grid container spacing={3}>
                            {props.missionRef && <Grid item id="mission" xs={12}>
                                <Typography color="black" variant="h6" ref={props.missionRef}>
                                    Mission
                                </Typography>
                                <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }} />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={7}>
                                        <Typography color="black" variant="h4" marginBottom={"0.5em"} >
                                            Leidenschaft für Hecken - seit 55 Jahren
                                        </Typography>
                                        <Typography color="black" variant="body1" fontSize={"15pt"} >
                                            <b>Willkommen auf unserer Webseite!</b>
                                            <br/><br/>Wir sind ein Familienunternehmen, das seit drei Generationen Hecken für unsere Kunden in höchster Qualität und Handwerkskunst anbietet. Unsere jahrzehntelange Erfahrung in der Branche hat uns zu Experten im Heckenbau gemacht, und wir haben uns einen Ruf für unser Engagement für Qualität und Kundenzufriedenheit erworben. Auf dieser Webseite erfahren Sie mehr über unser Unternehmen, unsere Geschichte und unser Angebot an Hecken und Services. Wir laden Sie herzlich ein, unsere Webseite zu erkunden und uns bei Fragen oder Interesse an unseren Dienstleistungen zu kontaktieren.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <img src={ExampleImage2} alt="Villa" style={{ width: "100%" }}></img>
                                        {/* <small>Bild von benzoix auf Freepik</small> */}
                                    </Grid>
                                    {isSmallScreen && <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 10px", width: "100%" }} />}
                                    <Grid item xs={12} lg={5}> <img src={ExampleImage3} alt="Construction" width="100%"></img></Grid>
                                    <Grid item xs={12} lg={7}>
                                        <Typography color="black" variant="h4" fontSize={"22pt"} marginBottom={"0.5em"} >Höchster Anspruch an Qualität</Typography>
                                        <Typography color="black" variant="body1" fontSize={"15pt"}>
                                            Als Familienunternehmen in der Landwirtschaft sind wir stolz auf unsere langjährige Erfahrung und unser Engagement für nachhaltige Produktion. Wir setzen auf biologische und natürliche Methoden, um unsere Pflanzen anzubauen und zu pflegen, und vermeiden den Einsatz von chemischen Pestiziden und Düngemitteln. Unsere Philosophie basiert auf der Überzeugung, dass die Boden- und Pflanzengesundheit die Grundlage für eine nachhaltige Landwirtschaft bildet.
                                        <br/><br/>
                                            Zudem erfordert der erfolgreiche Anbau von Thuja Smaragd-Pflanzen spezielle Kenntnisse und Techniken, um sicherzustellen, dass die Pflanzen gesund und robust wachsen. Hier sind einige wichtige Faktoren, auf die es bei Thuja Smaragd-Pflanzen ankommt:
                                        <ol>
                                            <li><b>Standort</b>: Thuja Smaragd-Pflanzen bevorzugen einen Standort mit viel Sonnenlicht und gut durchlässigem Boden. Der Boden sollte reich an Nährstoffen sein und gut entwässern, um Staunässe zu vermeiden.</li>
                                            <li><b>Bewässerung</b>: Thuja Smaragd-Pflanzen benötigen regelmäßige Bewässerung, um gesund zu bleiben. Es ist wichtig, dass der Boden gleichmäßig feucht gehalten wird, aber nicht zu nass, um Wurzelfäule zu vermeiden.</li>
                                            <li><b>Düngung</b>: Thuja Smaragd-Pflanzen sollten während des Wachstums regelmäßig gedüngt werden, um ein gesundes Wachstum zu fördern. Es ist wichtig, einen ausgewogenen Dünger zu verwenden, der reich an Stickstoff, Phosphor und Kalium ist.</li>
                                            <li><b>Krankheits- und Schädlingsbekämpfung</b>: Thuja Smaragd-Pflanzen sind anfällig für verschiedene Krankheiten und Schädlinge, wie zum Beispiel Thuja-Blasenrost und Spinnmilben. Es ist wichtig, regelmäßig auf Anzeichen von Krankheiten oder Schädlingen zu überprüfen und gegebenenfalls Maßnahmen zu ergreifen, um sie zu bekämpfen.</li>
                                        </ol>
                                            Durch die strengste Beachtung dieser Faktoren und sorgfältige Pflege können wir Ihnen gesunde und besonders robuste Thuja Smaragd-Pflanzen anbieten.
                                        </Typography>
                                    </Grid>
                                    {isSmallScreen && <Divider style={{ backgroundColor: "lightgrey", margin: "25px 0px 0px 10px", width: "100%" }} />}
                                    <Grid item xs={12} lg={8}>
                                        <br /> <br />
                                        <Typography color="black" variant="h4" fontSize={"22pt"} >Umfangreiche Leistungen</Typography>
                                        <Typography color="black" variant="body1" fontSize={"15pt"}>
                                            Neben dem einfachen Verkauf von Thuja Hecken bieten wir Ihnen auch gerne zusätzliche Leistungen an. Falls Ihre individuellen Bedürfnisse eine Lieferung am Wochenende oder die Unterstützung beim einflanzen bedürfen: Wir sind für Sie da! Lassen Sie unser Team einfach wissen, welche Leistungen Sie gerne wünschen.
                                            <br />
                                            <ol>
                                            <li><b>Heckenbäume</b>: Thuja Smaragd und Zypressen in den Größen 120cm bis 220cm.</li>
                                            <li><b>Lieferungen</b>: Nach Wunsch im Umkreis Berlin-Brandenburg. Bei größeren Bestellungen auch im weiteren Umkreis möglich (nur auf Nachfrage).</li>
                                            <li><b>Heckenbau</b>: Unterstützung bei der Pflanzung der erworbenen Thuja Bäume.</li>
                                            </ol>
                                         Falls Sie weitere Bedürfnisse haben, wie z.B. die Unterstützung beim Aufbau des Bewässerungssystems, lassen Sie uns gerne wissen!<br /> <b>Wir finden eine Lösung.</b>


                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={4}><br /><br /><img src={ExampleImage4} alt="Construction" width="100%"></img></Grid>
                                    <Grid item xs={12}></Grid>
                                    {isSmallScreen && <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 10px", width: "100%" }} />}


                                    <Grid item xs={12} lg={8}>

                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 30px 0px", }} />
                    <Grid item style={{ backgroundColor: "rgba(255, 255, 255, 0.95)", padding: isSmallScreen?"1.5em":"3em", borderRadius: "3px", width: "100%", width: "100%" }}>
                        <Grid container spacing={3}>
                            {props.clientsRef && <>
                                <Grid item id="clients" xs={12}>
                                    <Typography color="black" variant="h6" ref={props.clientsRef}>
                                        Kunden
                                    </Typography>
                                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 5px 0px" }} />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Typography color="black" variant="body1" fontSize={"15pt"}>
                                        Das sagen unsere Kunden:
                                    </Typography>
                                </Grid>
                                {cardLG && props.data["clients"].map((client, idx) => {
                                    if (idx <= shownCardsCount) {
                                        return (
                                            <Grid key={idx} item id="clients" xs={12} lg={cardLG[idx] ? cardLG[idx] : 6}>
                                                <Card client={client} setCardLG={setCardLG} cardLG={cardLG} idx={idx}></Card>
                                            </Grid>
                                        )
                                    } else {
                                        return (<></>)
                                    }
                                })}
                                <Grid item id="prject-buttons" xs={12}>
                                    {props.data["clients"].length > shownCardsCount && <Button variant="outlined" onClick={(e) => setShownCardsCount(shownCardsCount + 4)}>Zeige mehr</Button>}
                                    {props.data["clients"].length > 3 && shownCardsCount > 3 && <Button variant="outlined" onClick={(e) => setShownCardsCount(3)}>Verstecken</Button>}
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }} />
                    <Grid item style={{ backgroundColor: "rgba(255, 255, 255, 0.95)", padding: isSmallScreen?"1.5em":"3em", borderRadius: "3px", width: "100%" }}>
                        <Grid container spacing={3}>
                            {props.teamRef && <>
                                <Grid item id="mission" xs={12}>
                                    <Typography color="black" variant="h6" ref={props.teamRef}>
                                        Galerie
                                    </Typography>
                                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={9}>
                                            <Typography color="black" variant="h4" marginBottom={"0.5em"} >
                                                Ein Blick in unsere Arbeit
                                            </Typography>
                                            <Typography color="black" variant="body1" fontSize={"15pt"} >
                                            Gerne möchten wir Ihnen einen Einblick in die harte Arbeit geben, die mit dem Anbau und Vertrieb von Thuja Smaragd-Hecken verbunden ist. Es ist ein anspruchsvoller Prozess, der viel Zeit, Engagement und Fachwissen erfordert.
                                            <br/><br/>Zunächst müssen wir einen geeigneten Standort auswählen und den Boden sorgfältig vorbereiten, um sicherzustellen, dass er reich an Nährstoffen ist und eine gute Drainage bietet. Wir müssen auch sorgfältig planen, wie die Hecken angelegt und gepflegt werden, um ein gesundes Wachstum und eine dichte, gleichmäßige Hecke zu fördern.
                                            <br/><br/>Die Pflanzung und Pflege von Thuja Smaragd-Hecken erfordert regelmäßige Bewässerung, Düngung und Beschneidung sowie die Bekämpfung von Krankheiten und Schädlingen. Dies erfordert viel Zeit und Arbeit, um sicherzustellen, dass die Hecken gesund und robust wachsen.
                                            <br/><br/>Wenn die Hecken ausgewachsen sind, müssen wir sie sorgfältig ernten und verarbeiten, um sicherzustellen, dass sie in einem optimalen Zustand zum Verkauf bereit sind. Der Transport und Vertrieb der Hecken erfordert ebenfalls eine sorgfältige Planung und Organisation, um sicherzustellen, dass sie pünktlich und in bestem Zustand bei unseren Kunden ankommen.
                                            <br/><br/>Zusammenfassend kann man sagen, dass der Anbau und Vertrieb von Thuja Smaragd-Hecken eine anspruchsvolle und intensive Arbeit ist, die viel Engagement und Fachwissen erfordert. Wir sind stolz darauf, Ihnen hochwertige Thuja Smaragd-Hecken anbieten zu können und setzen alles daran, um sicherzustellen, dass sie in bestem Zustand bei Ihnen ankommen.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={3}>
                                            <img src={ExampleImage6} alt="Thuja" style={{ width: "100%" }}></img>
                                            {/* <small>Bild von Freepik</small> */}
                                        </Grid>
                                        {isSmallScreen && <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px", width: "100%" }} />}
                                        <Grid container spacing={3} justify="center" alignItems="center" style={{ margin: "1em 0px" }}>
                                            <Grid item xs={12} lg={5} align="center">
                                                <img src={ExampleImage7} alt="Anbau" style={{ width: "100%" }}></img>
                                                <Typography color="black">
                                                    <b>Anbau von Sprößlingen</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={3} align="center">
                                                <img src={ExampleImage5} alt="Villa" style={{ width: "100%" }}></img>
                                                <Typography color="black">
                                                    <b>Vermessung und Pflege</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={4} align="center">
                                                <img src={ExampleImage8} alt="Unkrautbeseitigung" style={{ width: "100%" }}></img>
                                                <Typography color="black">
                                                    <b>Unkrautbeseitigung (ohne Chemie)</b>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }} />
                    <Grid item style={{ backgroundColor: "rgba(255, 255, 255, 0.95)", padding: isSmallScreen?"1.5em":"3em", borderRadius: "3px", width: "100%" }}>
                        <Grid container spacing={3}>
                            {props.contactRef && <>
                                <Grid item id="mission" xs={12}>
                                    <Typography color="black" variant="h6" ref={props.contactRef}>
                                        Kontakt
                                    </Typography>
                                    <Divider style={{ backgroundColor: "lightgrey", margin: "5px 0px 20px 0px" }} />
                                    <ContactForm isSmallScreen={isSmallScreen}></ContactForm>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
            <div style={{ backgroundImage: `url('${BackgroundImage}')`, backgroundRepeat: "repeat-y", backgroundSize: "100%", opacity: 0.6, width: "100%", height: scrollHeight, position: "fixed", zIndex: "-1" }}></div>
            {/* <img src={ExampleHomeImg} alt="House Construction" style={{ backgroundImage: `url(${ExampleHomeImg})`, opacity: 0.4, width: "100%", position: "absolute", zIndex: "-1" }}></img> */}
        </Grid >
    );
}

export default Home;

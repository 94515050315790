// Components
import ResponsiveAppBar from "./navbar"
import Footer from "./footer"
import Home from "./home/home"
import Impressum from "./impressum"

// Packages
import { useRef } from "react"
import { useLocation } from 'react-router-dom'

// Import project data
import { data } from "../data.js"


export default function Root() {
    const location = useLocation();

    const missionRef = useRef(null);
    const clientsRef = useRef(null);
    const teamRef = useRef(null);
    const contactRef = useRef(null)

    return (
        <div id="root">
            <ResponsiveAppBar missionRef={missionRef} clientsRef={clientsRef} teamRef={teamRef} contactRef={contactRef} />
            {location.pathname === "/" && <Home missionRef={missionRef} clientsRef={clientsRef} teamRef={teamRef} data={data} contactRef={contactRef} />}
            {location.pathname === "/Impressum" && <Impressum></Impressum>}
            <Footer />
        </div >
    );
}